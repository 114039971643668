<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <v-row>
          <v-col cols="1" class="px-0 pl-3 pt-2 pb-2">
            <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
          </v-col>
          <v-col cols="11" class="pb-0 pl-0 pt-0 px-0" tabindex="-1">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="monedasItems"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          item-key="monedaId"
          show-expand
          :expanded.sync="expanded"
        >
          <template v-slot:[`header`]="">
            <thead>
              <tr>
                <th colspan="3" style="text-align: right">|</th>
                <th colspan="2" style="text-align: center">
                  Moneda por defecto
                </th>
                <th colspan="1" style="text-align: left">|</th>
                <th :colspan="showHelp ? 3 : 2" style="text-align: right"></th>
              </tr>
            </thead>
          </template>
          <!-- Expansion de usuario -->
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{
                    isExpanded ? "mdi-account-check" : "mdi-account"
                  }}</v-icon
                >
              </template>
              <span>Usuarios</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Fecha modificación:</strong>
              {{ item.fecModi }}
              <strong style="padding-left: 80px">Usuario modificación:</strong>
              {{ item.usuModi }}
            </td>
          </template>
          <template v-slot:[`item.habCarga`]="{ item }">
            <v-icon v-if="item.habCarga" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.habListado`]="{ item }">
            <v-icon v-if="item.habListado" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="6" class="pr-14">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="2" align="end" class="pr-0">
                  <v-btn
                    color="primary"
                    small
                    @click="openModalDifCambioAndLoad()"
                    class="to-right"
                  >
                    Diferencia de cambio
                  </v-btn>
                </v-col>
                <v-col cols="2" align="end" class="">
                  <v-btn
                    color="primary"
                    small
                    @click="goToCotizacionesMonedas()"
                    class="to-right"
                  >
                    Cotizaciones
                  </v-btn>
                </v-col>
                <!-- <v-col cols="2" align="end" v-if="showFunctions">
                  <v-btn
                    color="primary"
                    small
                    @click="openModalEdit()"
                    class="to-right"
                  >
                    Nueva Moneda
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-toolbar>
          </template>
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="showFunctions">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalEdit(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar moneda</span>
            </v-tooltip>
            <v-tooltip left v-if="showFunctions">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteMonedaModal(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar moneda</span>
            </v-tooltip>
          </template> -->
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-0 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="isOpenModalEdit"
      v-model="isOpenModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditMoneda
        :editMonedaItem="editMonedaItem"
        @closeAndReload="closeAndReload"
      ></EditMoneda>
    </v-dialog>
    <v-dialog
      v-model="openDiferenciaCambioModal"
      v-if="openDiferenciaCambioModal"
      @keydown.esc="openDiferenciaCambioModal = false"
      max-width="75%"
      persistent
    >
      <v-card class="py-0">
        <v-card-title>
          <v-row class="pb-0">
            <v-col cols="5"
              ><span class="primary--text">
                Configuración de diferencia de cambio
              </span>
            </v-col>
            <v-col cols="7" class="py-0 pr-2">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="to-right"
                    icon
                    @click="openDiferenciaCambioModal = false"
                  >
                    <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headersEmpresas"
                :items="empresas"
                :search="search"
                dense
                :hide-default-footer="empresas.length <= 10"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="10" align-self="center">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:thead="{ props }">
                  <thead>
                    <tr>
                      <!-- Renderizar cada header -->
                      <th
                        v-for="header in props.headers"
                        :key="header.text"
                        :class="header.alignClass"
                      >
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:[`item.tipoCompDifCambioNegativa`]="{ item }">
                  <v-col class="py-1 pr-0" align="right">
                    <v-autocomplete
                      style="max-width: 270px"
                      v-model="item.tipoCompDifCambioNegativa"
                      :items="
                        tiposComprobantesNegativos.filter((x) => {
                          return x.entFacId == item.entFacId;
                        })
                      "
                      ref="tipoComprobante1"
                      return-object
                      clearable
                      item-value="tcompId"
                      solo
                      dense
                      light
                      item-text="tcompNombre"
                      label="Tipo comprobante"
                      hide-details="true"
                      @change="item.isModi = true"
                    >
                    </v-autocomplete>
                  </v-col>
                </template>
                <template v-slot:[`item.tipoCompDifCambioPositiva`]="{ item }">
                  <v-col class="py-1 pr-0" align="right">
                    <v-autocomplete
                      style="max-width: 270px"
                      v-model="item.tipoCompDifCambioPositiva"
                      :items="
                        tiposComprobantesPositivos.filter((x) => {
                          return x.entFacId == item.entFacId;
                        })
                      "
                      ref="tipoComprobante2"
                      return-object
                      clearable
                      solo
                      item-value="tcompId"
                      item-text="tcompNombre"
                      dense
                      label="Tipo comprobante"
                      hide-details="true"
                      @change="item.isModi = true"
                    >
                    </v-autocomplete>
                  </v-col>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3"> </v-col>
            <v-col cols="9" class="py-0 pr-0 text-right pr-0" align="end">
              <v-btn
                :loading="loadingBtnSaveDifCambio"
                class="mr-4"
                color="primary"
                @click="saveDiferenciasCambio"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditMoneda from "@/components/modules/administracion-sistema/monedas/EditMoneda.vue";
export default {
  name: "MonedasGeneral",
  components: { PageHeader, DeleteDialog, GoBackBtn, Ayuda, EditMoneda },
  data: () => ({
    routeToGo: "ConfiguracionAdmSistema",
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    closeIcon: enums.icons.CLOSE,
    title: "Monedas",
    titleDelete: "¿Desea eliminar la moneda?",
    search: "",
    optionCode: enums.webSiteOptions.MONEDAS,
    idToDelete: null,
    openDiferenciaCambioModal: false,
    editMonedaItem: null,
    expanded: [],
    monedasItems: [],
    loadingBtnSaveDifCambio: false,
    headers: [
      {
        text: "Moneda",
        value: "monedaNom",
        sortable: false,
      },
      {
        text: "Sigla",
        value: "siglaMoneda",
        sortable: false,
      },
      {
        text: "Habilitada para consulta",
        value: "habListado",
        sortable: false,
        align: "center",
      },
      {
        text: "Fecha desde",
        value: "vigDesde",
        sortable: false,
        align: "center",
      },
      {
        text: "Fecha hasta",
        value: "vigHasta",
        sortable: false,
        align: "center",
      },
      { text: "", value: "actions", sortable: false, align: "end", width: "1%" },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end",
      },
    ],
    headersEmpresas: [
      {
        text: "Empresa",
        value: "entFacNombre",
        sortable: false,
        align: "start",
        alignClass: "text-center",
      },
      {
        text: "Diferencia cambio +",
        value: "tipoCompDifCambioPositiva",
        sortable: false,
        align: "center",
        alignClass: "text-center",
      },
      {
        text: "Diferencia cambio -",
        value: "tipoCompDifCambioNegativa",
        sortable: false,
        align: "center",
        alignClass: "text-center",
      },
    ],
    empresas: [],
    tiposComprobantesPositivos: [],
    tiposComprobantesNegativos: [],
    allowedActions: null,
    idToDelete: null,
    isLoading: false,
    showDeleteModal: false,
    loadingBtnDelete: false,
    showExpand: false,
    showFunctions: false,
    showHelp: false,
    showIcon: true,
    canEdit: true,
    canDelete: true,
    canCreate: true,
    isOpenModalEdit: false,
  }),
  computed: {
    filteredTiposComprobantesNegativos(entFacId) {
      return this.tiposComprobantesNegativos.filter((x) => {
        return x.entFacId == entFacId;
      });
    },
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    //this.setPermisos();
    this.loadMonedas();
  },
  methods: {
    ...mapActions({
      getMonedas: "AdministracionSistema/getMonedas",
      deleteMoneda: "AdministracionSistema/deleteMoneda",
      getEntidadesFacturantesDifCambio:
        "AdministracionSistema/getEntidadesFacturantesDifCambio",
      getTipoCompsDifCambio: "AdministracionSistema/getTipoCompsDifCambio",
      updateDifCambioEmpresas: "AdministracionSistema/updateDifCambioEmpresas",
      setAlert: "user/setAlert",
    }),
    //por el momento se deshabilitan los permisos para abm
    // setPermisos() {
    //   this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    //   this.allowedActions?.forEach(x => {
    //     switch (x.optionCode) {
    //       case enums.modules.adminSistema.permissions.ELIMINAR_MONEDA:
    //         this.canDelete = true;
    //         break;
    //       case enums.modules.adminSistema.permissions.EDITAR_MONEDA:
    //         this.canEdit = true;
    //         break;
    //       case enums.modules.adminSistema.permissions.NUEVA_MONEDA:
    //         this.canCreate = true;
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    // },
    async loadMonedas() {
      this.isLoading = true;
      const data = await this.getMonedas();
      this.monedasItems = data;
      this.isLoading = false;
    },
    deleteMonedaModal(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.monedaId;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      try {
        const response = await this.deleteMoneda(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Moneda eliminada con exito",
          });
          this.loadingBtnDelete = false;
          this.loadMonedas();
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadingBtnDelete = false;
      }
    },
    openModalEdit(item) {
      this.isOpenModalEdit = true;
      this.editMonedaItem = item;
    },
    goToCotizacionesMonedas() {
      this.$router.push({
        name: "CotizacionesMonedas",
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true,
      });
    },
    closeAndReload() {
      this.isOpenModalEdit = false;
      this.loadMonedas();
    },
    async openModalDifCambioAndLoad() {
      let tipoComps = await this.getTipoCompsDifCambio();
      this.tiposComprobantesNegativos = tipoComps.filter((x) => x.tcompEsNc);
      this.tiposComprobantesPositivos = tipoComps.filter(
        (x) => x.tcompEsFactura
      );
      this.openDiferenciaCambioModal = true;
      this.empresas = await this.getEntidadesFacturantesDifCambio();
    },
    async saveDiferenciasCambio() {
      this.loadingBtnSaveDifCambio = true;
      try {
        const response = await this.updateDifCambioEmpresas(
          this.empresas.filter((x) => x.isModi)
        );
        if (response) {
          this.setAlert({
            type: "success",
            message:
              "Comprobantes de diferencia de cambio actualizados con éxito",
          });
        }
      } catch {}
      this.loadingBtnSaveDifCambio = false;
      this.openDiferenciaCambioModal = false;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>
<style>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.no-upper-case {
  text-transform: none;
}
@media (min-width: 1080px) and (max-width: 1300px) {
  .text-center {
    text-align: right;
  }
}
</style>
